import language from "./language/en";

export default {
  common: {
    downloadOriginal: "Download original",
    downloadTranslated: "Download {language}",
    downloadBilingual: "Download Bilingual (Dual subtitles)",
    chooseLanguage: "Choose language",
    translate: "Translate",
    createNewSubtitle: "Create a new subtitle",
    or: "or",
    uploadSubtitleFile: "Upload your subtitle file",
    uploadSubtitleFiles: "Upload your subtitle files",
    clickToUpload: "Click to upload",
    supportedFormats: "Supported formats: {formats}",
    invalidFile: "Invalid file",
    translationError: "Translation error. Please try again.",
    unsavedChanges:
      "You have unsaved changes. Are you sure you want to leave this page?",
    confirmLeave: "Are you sure you want to leave this page?",
    orDragAndDrop: "or drag and drop",
    freeSubtitleEditor: "Free Subtitle Editor and Translator",
    isProcessing: "{name} is processing...",
    translations: "Translations",
    downloadSubtitle: "Download Subtitle",
    error: "Error",
    moreOptions: "More options",
    viewMore: "View more",
    seeAllFaqs: "See all FAQs",
    features: "Features",
    appScreenshot: "App Screenshot",
    supported: "Supported",
    unsupported: "Unsupported",
  },
  buttons: {
    srt: "SRT",
    webvtt: "WebVTT",
    txt: "TXT",
    translate: "Translate",
    removeTranslation: "Remove Translation",
    download: "Download",
    gotIt: "Got it!",
    tryAgain: "Try again",
    next: "Next",
    prev: "Prev",
    convertNewFiles: "Convert new files",
    translateNewFiles: "Translate new files",
    downloadAll: "Download all",
    convert: "Convert",
    showError: "Show error",
    back: "Back",
  },
  meta: {
    title: "Editsub - Edit And Translate Subtitles Online",
    description:
      "Effortlessly edit, translate, and manage subtitles with our powerful online tool. Streamline your subtitle workflow, save time, and enhance your content quality. Experience seamless subtitle creation and editing for all your projects.",
    keywords:
      "online subtitle editor, subtitle translation, subtitle tool, subtitle management, subtitle formatting, subtitle synchronization, subtitle conversion, subtitle creation, subtitle localization, subtitle timing, subtitle proofreading, subtitle export, subtitle import, subtitle collaboration, Editsub",
    author: "Editsub Team",
  },
  components: {
    menu: {
      chooseDownloadLanguage: "Choose download language",
      original: "Original",
      translations: "Translations",
      chooseFileExtension: "Choose file extension",
      txt: "TXT (Plain text)",
      file: "File",
      new: "New",
      open: "Open",
      tools: "Tools",
      removeTextFormatting: "Remove text formatting",
      allLines: "All lines",
      selectedLines: "Selected lines",
      selectAndSubsequentLines: "Select and subsequent lines",
      edit: "Edit",
      selectAll: "Select all",
      deselectAll: "Deselect all",
      deleteLines: "Delete lines",
      splitLines: "Split lines",
      duplicateLines: "Duplicate lines",
      removeOriginalText: "Remove original text",
      removeTranslationText: "Remove translation text",
      timing: "Timing",
      adjustAllTimes: "Adjust all times...",
      showEarlier: "Show earlier",
      showLater: "Show later",
      time: "Time (hh:mm:ss.SSS)",
      affect: "Affect",
      typeOfAffect: "Type of affect",
      startTime: "Start time",
      endTime: "End time",
      startAndEndTime: "Start and end time",
      exportAs: "Export as...",
      openRecent: "Open recent",
      video: "Video",
      openVideo: "Open video...",
      closeVideo: "Close video",
      showWaveform: "Show waveform",
      scrollToCurrentSubtitle: "Scroll to current subtitle",
      playCurrentSubtitle: "Play current subtitle",
    },
    editor: {
      duration: "Duration",
      from: "From (hh:mm:ss.SSS)",
      to: "To (hh:mm:ss.SSS)",
      durationTooltip: "The duration of the subtitle in seconds.",
      startTooltip: "The start time of the subtitle.",
      endTooltip: "The end time of the subtitle.",
      addTooltip: "Add a new subtitle",
      removeTooltip: "Remove the subtitle",
      mergeTooltip: "Merge the subtitle with the next one",
      addNewLine: "Add new line",
      enterSubtitleContent: "Enter subtitle content...",
      enterSubtitleTranslation: "Enter subtitle translation...",
    },
    cookieConsent: {
      message:
        "We utilize cookies to enhance your experience. By continuing to access this site, you consent to our use of cookies.",
      moreInfo: "More info",
    },
    themeToggle: {
      light: "Light",
      dark: "Dark",
      system: "System",
      theme: "Choose theme",
    },
    subtitleActionButtons: {
      insertBefore: "Insert before",
      insertAfter: "Insert after",
      duplicate: "Duplicate line",
      delete: "Delete line",
      split: "Split line",
      mergeLineBefore: "Merge with line before",
      mergeLineAfter: "Merge with line after",
    },
  },
  errors: {
    selectAtLeastOne: "Please select at least one option.",
    invalidFile: "Invalid file",
    invalidFileFormat: "Invalid file format",
    unknownError: "Unknown error",
    unableToTranslate: "Unable to translate subtitles. Please try again later.",
  },
  pages: {
    layout: {
      footer: {
        contact: "Contact",
      },
      navigation: {
        subtitleEditor: "Subtitle Editor",
        subtitleTranslator: "Subtitle Translator",
        subtitleConverter: "Subtitle Converter",
      },
    },
    home: {
      title: "Edit And Translate Subtitles Online",
      description:
        "Easily edit, translate, and manage your subtitles with our powerful online tool.",
    },
    convert: {
      title: "Subtitle Converter",
      description:
        "Change your subtitle files to different formats easily online. We support SRT, VTT, ASS, and other common subtitle types.",
      featureDescription:
        "Discover the power of our subtitle converter with these key features:",
      selectFormat: "Select a format",
      noFilesSelected: "No files selected",
      noConvertedFiles: "No converted files yet",
      dragAndDrop: "Drag files here or click to select",
      files: "Files",
      noValidFilesSelected: "No valid files selected",
      noFormatSelected: "No format selected",
      maxFilesExceeded:
        "The maximum number of files is {max}. Please delete some files before adding new ones.",
      features: [
        {
          title: "Efficient Batch Conversion",
          description:
            "Save time by converting multiple subtitle files simultaneously, optimizing your workflow.",
        },
        {
          title: "Comprehensive Format Support",
          description:
            "Support for a wide range of popular subtitle formats including SRT, VTT, ASS, SSA, and more, ensuring compatibility across various platforms.",
        },
        {
          title: "Fast and Intelligent Processing",
          description:
            "Automatically detect and convert file encodings, while processing even large subtitle files quickly thanks to powerful servers.",
        },
      ],
      howItWorksDescription:
        "Converting your subtitle files has never been easier. Follow these simple steps to transform your subtitles into your desired format:",
      howItWorks: [
        {
          title: "Upload Subtitle Files",
          description:
            "Select subtitles to convert. Drag and drop or import from cloud. Supports multiple files.",
        },
        {
          title: "Choose Output Format",
          description:
            "Select desired subtitle format from the list (SRT, VTT, ASS, etc.).",
        },
        {
          title: "Customize Settings (Optional)",
          description: "Adjust timing, text encoding, or styling as needed.",
        },
        {
          title: "Start Conversion",
          description:
            "Click 'Convert' to begin. Our servers process files quickly.",
        },
        {
          title: "Download Converted Subtitles",
          description:
            "Get your new subtitle files individually or as a zip package.",
        },
      ],
      steps: {
        upload: {
          shortTitle: "Upload",
          title: "Upload files",
          description:
            "Select subtitles to convert. Drag and drop or import from cloud. Supports multiple files.",
        },
        format: {
          shortTitle: "Format",
          title: "Select output format",
          description:
            "Select desired subtitle format from the list (SRT, VTT, ASS, etc.).",
        },
        download: {
          shortTitle: "Download",
          title: "Download converted files",
          description:
            "Get your new subtitle files individually or as a zip package.",
        },
      },
    },
    translate: {
      title: "Subtitle Translator",
      description:
        "Translate your subtitle files to different languages easily online. We support SRT, VTT, ASS, and other common subtitle types.",
      featureDescription:
        "Discover the power of our subtitle translator with these key features:",
      selectLanguage: "Select a language",
      noFilesSelected: "No files selected",
      noConvertedFiles: "No translated files yet",
      noLanguagesSelected: "No languages selected",
      keepOriginalText: "Keep original text (Bilingual subtitles)",
      features: [
        {
          title: "Efficient Batch Translation",
          description:
            "Save time by translating multiple subtitle files simultaneously, optimizing your workflow.",
        },
        {
          title: "Comprehensive Language Support",
          description:
            "Support for a wide range of popular subtitle languages, ensuring compatibility across various platforms.",
        },
        {
          title: "Fast and Intelligent Processing",
          description:
            "Automatically detect and translate file encodings, while processing even large subtitle files quickly thanks to powerful servers.",
        },
      ],
      howItWorksDescription:
        "Translating your subtitle files has never been easier. Follow these simple steps to transform your subtitles into your desired language:",
      howItWorks: [
        {
          title: "Upload Subtitle Files",
          description:
            "Select subtitles to translate. Drag and drop or import from cloud. Supports multiple files.",
        },
        {
          title: "Choose Output Language",
          description: "Select desired subtitle language from the list.",
        },
        {
          title: "Customize Settings (Optional)",
          description: "Adjust timing, text encoding, or styling as needed.",
        },
        {
          title: "Start Translation",
          description:
            "Click 'Translate' to begin. Our servers process files quickly.",
        },
        {
          title: "Download Translated Subtitles",
          description:
            "Get your new subtitle files individually or as a zip package.",
        },
      ],
      steps: {
        upload: {
          shortTitle: "Upload",
          title: "Upload files",
          description:
            "Choose subtitles to translate or drag and drop them from your computer. Multiple files are supported.",
        },
        language: {
          shortTitle: "Language",
          title: "Select output languages",
          description: "Select desired subtitle languages from the list.",
        },
        download: {
          shortTitle: "Download",
          title: "Download translated files",
          description:
            "Get your new subtitle files individually or as a zip package.",
        },
      },
    },
    privacyPolicy: {
      title: "Privacy Policy",
      description:
        "This policy was last updated in September 2024. Our Privacy Policy outlines how we collect, use, and protect your personal information when you use our website and services.",
      lastUpdated: "This policy was last updated in September 2024.",
      content: [
        {
          title: "Definitions",
          paragraphs: [
            "Personal data: any information relating to an identified or identifiable natural person.",
            "Processing: any operation performed on personal data, such as collection, recording, organization, storage, use, or disclosure.",
          ],
        },
        {
          title: "Service Registration",
          paragraphs: [
            "To use our services, you need to create an account. We process the following information:",
          ],
          list: [
            "Registration information (such as name and email)",
            "Login information (such as username and password)",
            "Communications from you (via email or contact forms)",
          ],
        },
        {
          title: "Purpose of Data Processing",
          list: [
            "Authentication during login",
            "Providing services and information through the website",
            "Managing your account",
            "Communicating with you",
          ],
        },
        {
          title: "Legal Basis",
          paragraphs: [
            "The processing of your data is based on your consent and our legitimate interest in providing and improving our services.",
          ],
        },
        {
          title: "Data Retention",
          paragraphs: [
            "We retain your data for as long as your account is active. After account deletion, data will be removed in the next database cleanup.",
          ],
        },
        {
          title: "Your Rights",
          paragraphs: [
            "You have the right to access, correct, delete your data, and limit data processing. To exercise these rights, please contact us at support@editsub.com.",
          ],
        },
        {
          title: "Security",
          paragraphs: [
            "We implement reasonable security measures to protect your data. However, no method of transmission over the internet is 100% secure.",
          ],
        },
        {
          title: "Cookies and Tracking Technologies",
          paragraphs: [
            "We use cookies and similar technologies to improve your experience on our website.",
          ],
          subsections: [
            {
              title: "Google Analytics",
              paragraphs: [
                "We use Google Analytics, a web analytics service provided by Google, Inc. Google Analytics uses cookies to help us analyze how users use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States.",
                "You can refuse the use of cookies by selecting the appropriate settings on your browser. However, please note that if you do this, you may not be able to use the full functionality of this website.",
              ],
            },
            {
              title: "Google AdSense",
              paragraphs: [
                "We use Google AdSense, an advertising service by Google, to display ads on our website. Google AdSense uses cookies to show ads relevant to you based on your browsing history.",
                "Google uses advertising technologies such as cookies, pixel tags, and browser-based storage technologies to display, deliver, and evaluate relevant ads. You can manage how Google advertises to you by accessing Google's Ad Settings page.",
              ],
            },
            {
              title: "Sentry",
              paragraphs: [
                "We use Sentry, an error tracking and performance monitoring service, to help us identify and fix issues in our application. Sentry collects information about errors and performance metrics, which may include some user data.",
                "The data collected by Sentry helps us improve the stability and performance of our website. It does not include personal information beyond what is necessary for error tracking and is handled in accordance with our privacy policy and Sentry's privacy practices.",
                "You can learn more about Sentry's privacy practices by visiting their Privacy Policy page.",
              ],
            },
          ],
        },
        {
          title: "Your Choices",
          paragraphs: [
            "Most browsers allow you to refuse cookies. However, refusing cookies may affect your experience on our website.",
            'You can also opt out of Google Analytics by using the <a href="https://tools.google.com/dlpage/gaoptout" class="text-blue-600 hover:underline">Google Analytics Opt-out Browser Add-on</a>.',
          ],
        },
        {
          title: "Policy Changes",
          paragraphs: [
            "We may update this policy from time to time. Please check regularly for any changes.",
          ],
        },
      ],
    },
    termOfUse: {
      title: "Terms of Use",
      description:
        "These terms were last updated in September 2024. Our Terms of Use outline the rules for using our website and services.",
      lastUpdated: "Last updated: September 2024",
      sections: [
        {
          title: "1. Acceptance of Terms",
          content: [
            'By accessing and using the Editsub (hereinafter referred to as "the Service"), you agree to comply with and be bound by these Terms of Use. If you do not agree to these terms, please do not use the Service.',
          ],
        },
        {
          title: "2. Description of Service",
          content: [
            "Editsub is an online tool that allows users to edit, create, and manage subtitle files for various video formats.",
          ],
        },
        {
          title: "3. User Responsibilities",
          content: [
            "3.1. You are responsible for maintaining the confidentiality of your account information.",
            "3.2. You agree to use the Service only for lawful purposes and in accordance with these Terms.",
            "3.3. You must not use the Service to infringe upon any third-party copyrights or other intellectual property rights.",
          ],
        },
        {
          title: "4. Intellectual Property",
          content: [
            "4.1. The Service and its original content, features, and functionality are owned by Editsub and are protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.",
            "4.2. Users retain all rights to the subtitle content they create or edit using the Service.",
          ],
        },
        {
          title: "5. User-Generated Content",
          content: [
            "5.1. By using the Service, you grant us the right to store and process your subtitle files solely for the purpose of providing and improving the Service.",
            "5.2. We do not claim ownership of your subtitle content.",
          ],
        },
        {
          title: "6. Prohibited Activities",
          content: ["Users are prohibited from:"],
          list: [
            "6.1. Using the Service for any illegal activities.",
            "6.2. Attempting to gain unauthorized access to the Service or its related systems.",
            "6.3. Interfering with or disrupting the Service or servers connected to the Service.",
            "6.4. Introducing malicious software or code to the Service.",
          ],
        },
        {
          title: "7. Disclaimer of Warranties",
          content: [
            'The Service is provided "as is" and "as available" without any warranties of any kind, either express or implied.',
          ],
        },
        {
          title: "8. Limitation of Liability",
          content: [
            "In no event shall Editsub be liable for any indirect, incidental, special, consequential or punitive damages arising out of or related to your use of the Service.",
          ],
        },
        {
          title: "9. Changes to the Service",
          content: [
            "We reserve the right to modify or discontinue, temporarily or permanently, the Service with or without notice.",
          ],
        },
        {
          title: "10. Privacy Policy",
          content: [
            "Your use of the Service is also governed by our Privacy Policy, which is incorporated into these Terms by reference.",
          ],
        },
        {
          title: "11. Governing Law",
          content: [
            "These Terms shall be governed by and construed in accordance with international law, subject to applicable local laws in the user's country of residence.",
          ],
        },
        {
          title: "12. Changes to Terms",
          content: [
            "We reserve the right to modify these Terms at any time. We will always post the most current version on our website. By continuing to use the Service after changes have been made, you agree to be bound by the revised Terms.",
          ],
        },
        {
          title: "13. Contact Us",
          content: [
            'If you have any questions about these Terms, please contact us at <a href="mailto:support@editsub.com">support@editsub.com</a>.',
          ],
        },
      ],
    },
    faqs: {
      title: "Frequently Asked Questions (FAQ)",
      description:
        "Here are some of the most common questions about Editsub. If you have any other questions that aren't addressed here, please don't hesitate to contact our support team.",
      questions: [
        {
          question: "Is Editsub really free?",
          answer:
            "Yes, Editsub is completely free to use. We do not charge any fees for our services or hide any premium features behind a paywall.",
        },
        {
          question: "What subtitle formats does Editsub support?",
          answer:
            "We support a wide range of popular subtitle formats including SRT, VTT, ASS, SSA, and more. If you need support for a specific format that's not listed, please contact us.",
        },
        {
          question: "How do I sync my subtitles with the video?",
          answer:
            "Editsub provides an intuitive interface for manually adjusting subtitle timings. You can easily shift, stretch, or compress subtitle timings to match your video content.",
        },
        {
          question: "Do I need to create an account to use Editsub?",
          answer:
            "While you can use basic features without an account, we recommend creating one to access advanced features like cloud storage and project saving.",
        },
        {
          question: "How secure is my data when I use Editsub?",
          answer:
            "We take data security very seriously. All data transmissions are encrypted, and we do not share your personal information or subtitle content with third parties. For more details, please refer to our Privacy Policy.",
        },
        {
          question: "Can I use Editsub on my mobile device?",
          answer:
            "Yes, our platform is responsive and works on most modern mobile browsers. However, for the best experience, we recommend using a desktop or laptop computer.",
        },
        {
          question: "Does Editsub offer translation services?",
          answer:
            "While we don't provide professional translation services, we do offer machine translation assistance to help you quickly translate your subtitles into multiple languages.",
        },
        {
          question: "Can I collaborate with others on a subtitle project?",
          answer:
            "Yes, our collaboration feature allows multiple users to work on the same project simultaneously. Simply share your project link with your team members.",
        },
        {
          question: "How do I report a bug or suggest a new feature?",
          answer:
            'We value your feedback! Please use the "Feedback" button in the app or email us at support@editsub.com to report bugs or suggest new features.',
        },
        {
          question:
            "Is there a limit to the size of subtitle files I can upload?",
          answer:
            "There is a limit of 10MB for subtitle files. If you need to work with larger files, please contact our support team for assistance.",
        },
        {
          question: "How often is Editsub updated?",
          answer:
            "We strive to improve our service continuously. Major updates are typically released monthly, with minor updates and bug fixes rolled out more frequently.",
        },
        {
          question:
            "Can I use subtitles created with Editsub for commercial purposes?",
          answer:
            "Yes, you retain full rights to any subtitles you create using our tool. You're free to use them for personal or commercial purposes.",
        },
      ],
      contactSupport:
        "If you have any other questions that aren't addressed here, please don't hesitate to contact our support team.",
    },
    features: {
      title: "Discover the powerful features",
      description:
        "Explore our comprehensive suite of powerful features designed to revolutionize your subtitle editing experience",
      list: [
        {
          name: "Subtitle Editing",
          description:
            "Edit subtitles easily with an intuitive interface. Adjust timings, correct spelling errors, and format text quickly.",
        },
        {
          name: "Automatic Translation",
          description:
            "Translate subtitles into multiple languages with just one click. Utilize advanced AI technology to ensure high accuracy.",
        },
        {
          name: "Multi-format Export",
          description:
            "Export subtitles to popular formats like SRT, VTT, ASS. Compatible with most video platforms and editing software.",
        },
      ],
    },
    howItWorks: {
      title: "How It Works",
      description:
        "Editsub simplifies the subtitle editing process in just a few easy steps.",
      steps: [
        {
          title: "Upload Your Subtitle",
          description:
            "Start by uploading your subtitle file or pasting your subtitle text directly into the editor.",
        },
        {
          title: "Edit Subtitles",
          description:
            "Use our intuitive editor to modify existing subtitles or create new ones from scratch.",
        },
        {
          title: "Translate and Sync",
          description:
            "Automatically translate your subtitles to multiple languages and adjust timings if needed.",
        },
        {
          title: "Preview and Adjust",
          description:
            "Review your subtitles in a text-based preview and make any necessary adjustments.",
        },
        {
          title: "Export and Share",
          description:
            "Export your finished subtitles in your preferred format and share them with your audience.",
        },
      ],
    },
  },
  language,
};
